* {
  font-family: "Maven Pro", sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #27154f;
  text-transform: none !important;
}

*:focus {
  outline: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.App {
  text-align: center;
  background-color: #dce0e9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 5px;
}

.container {
  max-width: 1268px;
  width: 100%;
  padding: 0;
}

.header {
  background-color: #dce0e9;
  box-shadow: none;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 82px;
  position: relative;
  background-color: #dce0e9;
}

.header__container:before {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  top: 50%;
  bottom: 0;
  left: 100px;
  right: 100px;
  border-radius: 30%;
}

.header__button {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: #EAEAEA;
  background-color: #27154f;
  border-radius: 25px;
  padding: 13px 40px;
  line-height: 26px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
}

.header__button:hover {
  background-color: #27154f;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
}

.header__button svg {
  margin-right: 12px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 112px 0 116px;
  max-width: 1368px;
  margin: 0 auto;
  width: 100%;
}

main .container {
  display: flex;
  flex-direction: column;
}

.hero {
  background: url(./assets/background-stage.jpg);
  border-radius: 30px;
  padding: 120px 24px;
  margin-bottom: 120px;
  position: relative;
}

.hero__title {
  font-size: 54px;
  color: white;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 22px;
}

.hero__text {
  font-size: 22px;
  line-height: 32px;
  max-width: 728px;
  margin: 0 auto 60px;
  color: white;
}

.connector {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 50%;
  transform: translate(50%, 50%);
  bottom: 0;
}

.actions {
  width: fit-content;
  font-size: 18px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px 30px;
  grid-template-areas:
    "actions__select-label actions__select-wrapper actions__select-wrapper"
    "actions__input-label actions__input-wrapper actions__input-wrapper"
    ". actions__button-wrapper .";
}

.actions__select-label {
  grid-area: actions__select-label;
  justify-self: end;
  align-self: center;
  color: white;
}

.actions__select-wrapper {
  grid-area: actions__select-wrapper;
  justify-self: baseline;
  width: 100%;
  max-width: 400px;
}

.actions__select,
.actions__input {
  width: 100%;
  border-radius: 30px;
  font-size: 18px;
  color: #000154;
  background-color: white;
  text-align: left;
  padding: 0 15px;
}

.actions__input-label {
  grid-area: actions__input-label;
  justify-self: end;
  align-self: center;
  color: white;
}

.actions__input-wrapper {
  grid-area: actions__input-wrapper;
  justify-self: baseline;
  width: 100%;
  max-width: 400px;
}

.actions__button-wrapper {
  grid-area: actions__button-wrapper;
}
.actions__generate-button {
  background-color: #fdffa3;
  color: #000154;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 20px;
}

.actions__generate-button:hover {
  background-color: #fdffa3;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
  color: #000154;
}

.actions__generate-button:disabled {
  background-color: #fdffa3!important;
  color: #000154;
  opacity: .5;
}

.result-section {
  margin: 0 auto 120px;
  max-width: 728px;
  width: 100%;
}

.result-preview {
  padding: 44px;
  border: 3px white dashed;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  font-weight: 700;
}

.result-preview__title {
  font-size: 26px;
  margin-bottom: 32px;
}

.result-preview__text {
  line-height: 26px;
  text-align: center;
}

.result-preview__error {
  color: red;
}

.result-preview__loader {
  width: 64px;
  height: 64px;
  margin-bottom: 21px;
}

.result-generated__heading {
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 20px;
}

.result-generated__card {
  background-color: white;
  border-radius: 10px;
}

.result-generated__text {
  padding: 30px;
  border-bottom: 1px solid#8C9AB8;
  text-align: justify;
  line-height: 26px;
}

.result-generated__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 30px;
}

.webinars {
  padding: 0 30px;
}

.webinars__title {
  font-size: 54px;
  font-weight: 700;
  margin-bottom: 22px;
}

.webinars__text {
  font-size: 22px;
  line-height: 32px;
  max-width: 728px;
  margin: 0 auto;
}

.webinars__link:hover {
  text-decoration: none;
}

.webinars__heading {
  margin-bottom: 60px;
}

.webinars__list {
  max-width: 728px;
  margin: 0 auto 60px;
  border-top: 1px dashed #27154f;
}

.webinar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-bottom: 1px dashed #27154f;
  flex-wrap: wrap;
}

.webinar-item--closed .webinar-item__column {
  opacity: .5;
}

.webinar-item__status {
  background-color: #27c9b6;
  border-radius: 50%;
  width: 19px;
  height: 19px;
}

.webinar-item--closed .webinar-item__status {
  background-color: #c92775;
}

.webinar-item__value {
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
}

.webinar-item__button-wrapper {
  min-width: 230px;
  display: flex;
}

.webinar-item__button,
.webinar-modal__button {
  font-weight: 700;
  font-size: 16px;
  color: #eaeaea;
  background-color: #27154f;
  border-radius: 25px;
  padding: 13px 36px;
  line-height: 26px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
}

.webinar-item__button:hover,
.webinar-modal__button:hover {
  background-color: #27154f;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
}

.webinar-item__button:disabled {
  color: #27154f;
  background-color: #afadc3;
}



.rocket-logo {
  position: relative;
  margin-bottom: 40px;
}

.rocket-logo__divider {
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -50%);
}

.rocket-logo__image {
  position: relative;
}

.copy-button {
  font-weight: 700;
  color: #27154f;
  font-size: 16px;
}

.footer {
  max-width: 1368px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 50px;
}

.footer * {
  color: white;
}

.footer__inner {
  background: url(./assets/background-footer.jpg) center;
  border-radius: 30px;
  padding: 55px 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__info {
  display: flex;
  align-items: center;
}

.footer__copyrights {
  margin-right: 24px;
  text-align: start;
}

.arrow-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
}

.arrow-link:first-of-type {
  margin-right: 24px;
}

.arrow-link:hover {
  text-decoration: underline;
}

.arrow-link svg {
  margin-right: 12px;
}

.webinar-modal {
  border-radius: 20px;
  padding: 24px;
  max-width: 646px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.webinar-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -15px 0 38px;
}

.webinar-modal__close-button {
  margin: 0 -15px 0 0;
}

.webinar-modal__title {
  color: #000154;
  font-size: 22px;
}

.webinar-modal__body {
  margin-bottom: 32px;
}

.webinar-modal__description {
  margin-bottom: 24px;
}

.webinar-modal__date {
  font-weight: 700;
}

.webinar-modal__button {
  margin-left: auto;
}

@media (max-width: 1024px) {
  .footer__inner {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer__info {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer__info > * {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .header__container {
    padding: 24px;
  }

  .logo {
    max-width: 100px;
  }

  .hero {
    padding: 60px 24px 90px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 332px;
  }

  .footer__inner {
    padding: 24px;
  }
}

@media (max-width: 600px) {
  .webinar-item__button-wrapper {
    width: 100%;
    margin-top: 20px;
  }

  .webinar-item__button-wrapper, .webinar-item__button {
    width: 100%;
  }

  .webinar-item {
    padding: 30px 0;
  }
}
